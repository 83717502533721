import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/layout/head'
import Footer from './components/layout/footer'
import BodyCom from './components/home/body'

class App extends React.PureComponent {
  componentDidMount(){
    if(window._isPhone){
      document.getElementsByTagName('html')[0].style.fontSize=window.screen.width>=350? '6px':'5px'
    }
    window.addEventListener('resize',()=>{
      if(window.screen.width<600){
        window.location.reload()
      }
    })
  }
  render() {
    return (
      <div className="App">
         
          <BodyCom></BodyCom>
          <Footer />
          <Header></Header>
      </div>
    );
  }
}

export default App;
