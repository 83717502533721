
import React from 'react'

import Carousel from './bodyCarousel'
import Content from './bodyContent'
import Ad from './bodyAd'
import BMap from './bodyMap'

export default ()=>{
    return <div className="main">
    <div id="home" className="anchor"></div>
        <Carousel />
        <div id="product" className="anchor"></div>
        <Content />
        <div id="service" className="anchor"></div>
        <Ad />
        <div id="contact" className="anchor"></div>
        <BMap />
    </div>
}