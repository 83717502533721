import React, { Component } from 'react';
import icon from './logo.png'
import headData, { loginUrl } from './headData'
import scrollTo from '../../libs/scrollTo'

import Menu from 'antd/lib/menu'
import Dropdown from 'antd/lib/dropdown'
import Icon from 'antd/lib/icon'




export default class Header extends React.Component {
    constructor() {
        super();
        this.state = {
            activeItem: 'home',
            visible: false,
            isPhone: window._isPhone,
        }
    }
    componentDidMount() {
        this.scrollTo = scrollTo()

        // 变色
        let _cached = {}
        let container = document.getElementsByTagName('html')[0]

        let middle = document.documentElement.clientHeight / 2;
        window.onscroll = (e) => {
            let st = container.scrollTop;
            if (!this.scrollTo.isScroll) {
                // 变色
                let _actItem = ''
                if (st < 100) {
                    _actItem = 'home'
                }
                else {
                    ['home', 'product', 'service', 'contact'].forEach(item => {
                        if (_actItem) return
                        let el = _cached[item] || document.getElementById(item)
                        _cached[item] = el;

                        let rect = el.getBoundingClientRect();
                        let t = rect.y || rect.top;
                        if (Math.abs(t - middle) < 50) {
                            _actItem = item
                        }
                    })
                }
                if (_actItem) {
                    this.setState({
                        activeItem: _actItem
                    })
                }

            }
        }
    }

    handleVisibleChange = flag => {
        this.setState({
            visible: flag
        })
    }

    click = (e) => {
        let aim = e.target.dataset.src.slice(1);
        this.setState({
            activeItem: aim,
            visible: false,
        })
        e.preventDefault();
        e.stopPropagation()

        this.scrollTo(aim)
    }

    renderMenus() {
        if (this.state.isPhone) {
            const menu = <Menu >
                {
                    headData.map(item => {
                        return <Menu.Item data-src={item.href} key={item.text}>
                            <span className={item.href.slice(1) === this.state.activeItem ? "active" : ''} onClick={this.click} data-src={item.href} href={item.href}>{item.text}</span>
                        </Menu.Item>
                    })
                }
                <Menu.Item>
                    <a href={loginUrl} className="btn-blue">系统登录</a>
                </Menu.Item>
            </Menu>
            return <Dropdown overlay={menu} onVisibleChange={this.handleVisibleChange}
                visible={this.state.visible}>
                <a className="ant-dropdown-link" href="#hello">
                    菜单 <Icon type="down" />
                </a>
            </Dropdown>
        }

        return <ul>
            {
                headData.map(item => {
                    return <li key={item.text}>
                        <a className={item.href.slice(1) == this.state.activeItem ? "active" : ''} onClick={this.click} data-src={item.href} href={item.href}>{item.text}</a>
                    </li>
                })
            }
            <li>
                <a href={loginUrl} className="btn-blue">系统登录</a>
            </li>
        </ul>
    }


    render() {
        return <div className="header">
            <div className="container">
                <img className="h-icon" alt="logo" src={icon}></img>
                <div className="h-right">
                    {this.renderMenus()}
                </div>
            </div>
        </div>
    }
}