import React from 'react'
import Carousel from 'antd/lib/carousel'

import imgbg from './imgs/1.png'
import img1 from './imgs/ipad.png'
import img2 from './imgs/mac.png'


class CarouseItem extends React.Component {
    render() {
        return <div className=" container">
            <div className="body-carousel-left" key="0">
                <div style={{ paddingTop: this.props.pt + 'rem' }}>
                    <h1>云逍</h1>
                    <span className="span-blue">让美变得更简单</span>
                </div>
            </div>
            <div className={`body-carousel-right body-carousel-${this.props.data.cla}`} key="1">
                <img alt="pic" src={this.props.data.src}></img>
            </div>
        </div>
    }
}


export default () => {
    const data = [
        { cla: '1', src: img1, pt: '4' }, { cla: '2', src: img2, pt: '9' }
    ]
    return <div className="body-carousel"><Carousel >
        {
            data.map(item => {
                return <CarouseItem pt={item.pt} key={item.cla} data={item} />
            })
        }
    </Carousel></div>
}