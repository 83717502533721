export default [{
    text:'首页',
    href:'#home'
},{
    text:'产品介绍',
    href:'#product'
},{
    text:'用户服务',
    href:'#service'
},{
    text:'联系我们',
    href:'#contact'
}]

export const loginUrl="https://shop.erpyx.com/"
