/**简单的滚动 */
export default () => {
    let aimSt = 0;
    let speed = 0;
    

    function scrollTo(container) {
        aimScroll.isScroll = true;
        let st = container.scrollTop;
        if (Math.abs(st - aimSt) < 1) {
            aimScroll.isScroll = false;
            return;
        }
        speed = Math.abs(speed) < Math.abs(aimSt - container.scrollTop) ? speed : aimSt - container.scrollTop
        container.scrollTop += speed;

        requestAnimationFrame(() => {
            scrollTo(container)
        })
    }
    

    function getY(el) {
        let rect = el.getBoundingClientRect()
        return rect.y || rect.top;
    }

    let container = document.getElementsByTagName('html')[0]

    function aimScroll(aim){
        let aimDom = document.getElementById(aim)
        if (!aimDom) {
            return;
        }
        let disHeight = 62
        let time = 250;
        let top = getY(aimDom);

        let st = container.scrollTop;

        st += top - disHeight
        aimSt = st;

        speed = (aimSt - container.scrollTop) / (time / 17)

        scrollTo(container)
    }
    
    aimScroll.isScroll = false;

    return aimScroll

}