import React from 'react'

export default ()=>{
    return <div className="body-content">
        <div className="container">
        <h1>云逍信息技术平台</h1>
        <p>
        针对企业应用、信息化管理提供整体解决方案。 
        </p><p>
        平台包含连锁店管理、会员管理、移动营销、库存管理、员工管理、收银管理、开支管理、员工自助查询等功能。支持折扣卡，储值卡，计次卡，疗程卡，期限卡等多种会员卡。支持微信、支付宝等新颖的移动支付方式。 
        为运营和决策层展现丰富管理报表，帮助企业员工及管理人员随时随地处理工作和获取企业经营信息，助力企业全面提高工作效率、优化成本结构、提升服务质量。 
        </p><p>
        采用SAAS模式，应用公有云平台，实现企业总部与各分支机构，甚至供应商和分销商在同一平台上协同办公，实现对异地分支机构销售、库存、费用、办公等信息的全方位实时掌控。 
        </p><p>
        通过海量数据分析及数据挖掘，帮助企业实现营运智能化、管理人性化、服务标准化。
        </p>
        </div>
        
    </div>
}