import React from 'react'

import imgBg from './imgs/2.png'

export default () => {
    return <div className="body-ada">
        <div className="span-blue container">
            <h1>客户服务理念</h1>
            <span>我们恪守“用户至上”的服务理念。 </span>
            <p>
                通过产品实时运维信息平台、标准化分布式服务站点、高效严密的服务流程体系和24小时快速响应机制，为客户数据安全、营销、营运、管理提供服务和保障。
        </p>
        </div>

    </div>
}