import React from 'react'
import drawMap from './drawMap'
import Icon from 'antd/lib/icon'
import mapIcon from './imgs/map.png'



export default class BodyMap extends React.Component {
    render() {

        let search = "http://api.map.baidu.com/place/search?res=jsapi&query=云逍信息技术有限公司&region=昆明市&output=html"
        const data = [
            {
                text: '公司地址：云南省 昆明市 五华区 鼓楼路311号',
                icon: 'environment'
            },
            {
                text: '电子邮件：834620951@qq.com',
                icon: 'mail'
            },
            {
                text: '售后服务：0871-63582275',
                icon: 'customer-service'
            },
            {
                text: '微信平台: 云逍信息技术服务',
                icon: 'wechat'
            }
        ]
        return <div className="body-map">
            <div className="container">
                <div className="body-map-left">
                    <h1>联系我们</h1>
                    {
                        data.map((item, index) => {
                            return <div className='bma clear' key={index}>
                                {/* <img className="icon" src={item.icon}></img> */}
                                <Icon className="icon" type={item.icon}></Icon>
                                <p className="text">{item.text}</p>
                            </div>
                        })
                    }
                    <div className="code"></div>
                </div>
            </div>

            {/* <div id="myMap"></div> */}
            <div id="myMap1">
                <a href={search} target="__blank">
                </a>
            </div>
        </div>
    }

    componentDidMount() {
        // drawMap('myMap')
    }
}